import { Button } from 'components'
import { darkBlueGrey, lightGold, mykonosBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`

  border: solid 1px #dee2ee;
  border-radius: 8px;
  padding: 1rem 2.5rem 6rem;
  margin-left: 8rem;
  height: fit-content;
  flex: 0 0 60%;

  > svg {
    display: block;
    margin: auto;
    width: auto;
    height: 4rem;
  }

  > h4 {
    font-size: 1.5rem;
    color: ${darkBlueGrey};
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  > p {
    font-size: 0.875rem;
    color: ${darkBlueGrey};
    letter-spacing: 0.25px;
    font-weight: normal;
  }

  span {
    font-weight: normal;
    margin-bottom: 5px;
  }

  > a {
    color: ${darkBlueGrey};
    font-size: 0.875rem;
    margin-top: 2rem;
    display: block;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.25px;
  }

  ${IS_FRAME_MOBILE} {
    margin-left: 0;
    margin-top: 2rem;
    padding: 0 1rem 2.5rem;

    > svg {
      margin-top: 0.75rem;
      width: 5rem;
      height: 4rem;
    }

    > h4 {
      margin: 0.5rem 0;
      font-size: 1.25rem;
    }

    > p {
      line-height: 1.57;
      margin-top: 0.25rem;
    }

     span {
      font-size: 0.875rem;
    }
     
     input {
      font-size: 0.875rem;
    }
  }
  ${IS_FRAME_TABLET} {
    width: 94%;
    margin-left: 0;
    padding: 1rem 1.125rem 5rem;

    > svg {
      margin-top: 0;
      width: 18%;
      height: 4rem;
      margin-bottom: 1rem;
    }

    > h4 {
      margin: 0.5rem 0;
      font-size: 1.25rem;
    }

    > p {
      line-height: 1.57;
      margin-top: 0.25rem;
      width: 40%;
    }

    span {
      font-size: 0.875rem;
    }
  }
`

export const LoginButton = styled(Button)`
  background-color: ${lightGold};
  border-color: ${lightGold};
  color: ${mykonosBlue};
  width: 100%;
  padding: 0.5rem 0;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 0.875rem;
  margin-top: 1rem;
  text-transform: uppercase;
  height: 3rem;

  &:not([disabled]):hover {
    color: #fff;
    background-color: ${mykonosBlue};
    border: solid 1px #fff;
  }
  ${IS_FRAME_TABLET} {
    color: ${darkBlueGrey}
  }
` 

export const ForgotWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  &:hover {
    text-decoration: underline;
    color: blue;
  }
`

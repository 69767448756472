import { darkBlueGrey } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Row = styled.div`
  display: flex;
  padding: 3rem 9rem;
  align-items: center;

  ${IS_FRAME_MOBILE} {
    display: block;
    padding: 1.5rem 1.25rem 0.5rem;
  }
  ${IS_FRAME_TABLET} {
    flex-direction: column;
    padding: 3rem 5rem;
  }
`

export const Col = styled.div`
  flex: 2 0 50%;
  justify-content: flex-end;

  > h1 {
    color: ${darkBlueGrey};
    letter-spacing: 0.25px;
    font-size: 2rem;
    font-weight: normal;
  }
`
Col.Form = styled(Col)`
  flex: auto;
  display: inherit;
  align-items: center;

  ${IS_FRAME_TABLET} {
    width: 100%;
    display: block;
    margin-top: 1rem;
  }
`
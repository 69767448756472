import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LogoQlue } from '@qlue/icon-kit'
import { InputText } from 'components'
import {
  Wrapper,
  LoginButton,
  ForgotWrapper
} from './styles'
import { AffiliateAuthURL } from 'commons/API'
import { useToasts } from 'react-toast-notifications'
import { useAuth } from '../../../../../providers/auth-provider'
import { FormattedMessage } from 'utils/intl'
import { useIntl } from 'react-intl'
import AffiliateAPI from 'commons/API/affiliate'
import {  navigate } from 'gatsby'
import { generateElementID, LOGIN_AFFILIATE_ID_PREFIX, FIELD_PASSWORD_ID_PREFIX } from 'utils/generateElementID'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Your email is not valid')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .required('Required')
})

const LoginForm = () => {

  const {
    onLogin, 
    saveToken 
  } = useAuth()
  
  const { addToast } = useToasts()
  const intl = useIntl()

  const [errorResponse, setErrorResponse] = useState({
    email    : '',
    password : ''
  })

  const { 
    handleSubmit, 
    formState: {
      errors,
      isSubmitting
    }, 
    control
  } = useForm({
    resolver      : yupResolver(ValidationSchema),
    mode          : 'onTouched',
    defaultValues : {
      email    : '',
      password : ''
    }
  })

  const handleSuccess = (data) => {
    onLogin()
    saveToken({ access_token: data.access_token, refresh_token: data.refresh_token })
  }

  const handleSubmitForm = async (values) => {
    await AffiliateAPI.post(AffiliateAuthURL.PostLoginAffiliate, values)
      .then((response) => handleSuccess(response.data))
      .catch((error) => {
        if(error.message === 'Network Error') {
          addToast(`${error.message}. Please Check Your Internet Connection`, { appearance: 'warning' })
        }

        else if(error.response.data.message.toLowerCase().includes('password')) {
          errorResponse.password = error.response.data.message
          errorResponse.email = ''
        }
        else if(error.response.data.message.toLowerCase().includes('email')) {
          errorResponse.email = error.response.data.message
          errorResponse.password = ''
        }

        setErrorResponse({ ...errorResponse })
      })
  }

  const fieldErrorMsg = (errorForm, errorResponse) => {
    if(errorForm) return errorForm
    else if(errorResponse) return errorResponse

    return ''
  }

  return(
    <Wrapper>
      <LogoQlue />
      <h4>
        <FormattedMessage id="16b826323" />
      </h4>
      <p>
        <FormattedMessage id="f8a5f6ebe" />
      </p>
      <form 
        onSubmit={ handleSubmit(handleSubmitForm) }
        autoComplete='off'
      >

        <Controller 
          render={ ({ field, ref }) => (
            <InputText 
              title={ intl.formatMessage({ id: 'a8ebc8742' }) }
              placeholder={ intl.formatMessage({ id: 'd100971b3' }) }
              error={ fieldErrorMsg(errors.email?.message, errorResponse.email) }
              ref={ field.ref }
              onFocus={ () => setErrorResponse({
                ...errorResponse,
                email: ''
              }) }
              { ...field }
              id={ generateElementID(LOGIN_AFFILIATE_ID_PREFIX, 'email') }
            />
          ) }
          control={ control }
          name='email'
        /> 

        <Controller 
          render={ ({ field, ref }) => (
            <InputText 
              title={ intl.formatMessage({ id: '13c7d3bb2' }) }
              placeholder={  intl.formatMessage({ id: '6fc7b06e2' }) }
              type="password"
              error={ fieldErrorMsg(errors.password?.message, errorResponse.password) }
              ref={ field.ref }
              onFocus={ () => setErrorResponse({
                ...errorResponse,
                password: ''
              }) }
              { ...field }
              id={ generateElementID(FIELD_PASSWORD_ID_PREFIX) }
            />
          ) }
          control={ control }
          name='password'
        /> 

        <ForgotWrapper>
          <span 
            onClick={ () => navigate('/affiliate/forgot-password') }
            id={ generateElementID(LOGIN_AFFILIATE_ID_PREFIX, 'btnloginforgotpassword') }
          >
            forgot password?
          </span>
        </ForgotWrapper>

        <LoginButton 
          type='submit' 
          disabled={ isSubmitting }
          id={ generateElementID(LOGIN_AFFILIATE_ID_PREFIX, 'btnlogin') }
        >
          <FormattedMessage id="16b826323" />
        </LoginButton>
      </form>
    </Wrapper>
  )
}

export default LoginForm


import React from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { useAuth } from '../providers/auth-provider'
import { Shimmer } from 'components'

const withAuthRedirect = ({
  WrappedComponent,
  expectedAuth,
  redirectUrl
}) => {
  
  const WithAuthRedirectWrapper = (props) => {
    const { 
      isAuthLoading, 
      isLoggedIn 
    } = useAuth()
    const location = useLocation()

    const back = location?.search.split('redirectUrl=').pop()

    let nextPath 
    if(back) nextPath = back
    else nextPath = redirectUrl

    // Always true on servce since AuthProvider only calls endpoint in useEffect
    if (isAuthLoading) {
      return (
        <>
          <Shimmer/>
          <Shimmer/>
          <Shimmer/>
          <Shimmer/>
          <Shimmer/>
        </>
      )
    }

    const isClient = typeof window !== 'undefined'

    // navigate to login page with redirectPath when user try to access private page
    if (isClient && expectedAuth && !isLoggedIn) {
      navigate(nextPath)
      return <></>
    }
    // navigate to /affiliate-dashboard/analytics page after success login
    else if (isClient && !expectedAuth && isLoggedIn) {
      navigate(nextPath)
      return <></>
    }

    return (<WrappedComponent { ...props } />)
  }

  return WithAuthRedirectWrapper
}

export default withAuthRedirect

import withAuthRedirect from './with-auth-redirect'

/**
 * Wrapper HOC that explicity sets expectedAuth: FALSE to
 *   implicityly provide a HOC meant for authenticated pages
 *   outside of this component
 *
 * This
 * @param {React.Component} WrappedComponent
 * @param {String} redirectUrl
 */
const withoutAuth = (WrappedComponent, redirectUrl, location) => (
  withAuthRedirect({
    WrappedComponent,
    redirectUrl,
    location,
    expectedAuth: false
  })
)

export default withoutAuth

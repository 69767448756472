import React from 'react'
import BannerImg from 'media/images/affiliate-dashboard/login/banner.svg'
import {
  Wrapper,
  Img
} from './styles'
import { FormattedMessage } from 'utils/intl'
import { generateElementID, LOGIN_AFFILIATE_ID_PREFIX } from 'utils/generateElementID'

const LoginBanner = () => (
  <Wrapper>
    <h1>
      <FormattedMessage id="01b5b7f8a" />
    </h1>
    <p>
      <FormattedMessage id="96af81bce" />
    </p>
    <Img id={ generateElementID(LOGIN_AFFILIATE_ID_PREFIX, 'image') } src={ BannerImg } alt='banner'/>
  </Wrapper>
)

export default LoginBanner

import React from 'react'
import { LoginBanner, LoginForm } from './components'
import {
  Row,
  Col
} from './styles'

const LoginView = () => (
  <Row>
    <Col>
      <LoginBanner />
    </Col>
    <Col.Form>
      <LoginForm />
    </Col.Form>
  </Row>
)

export default LoginView

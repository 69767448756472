import React from 'react'
import LoginView from 'domains/AffiliateDashboard/Login'
import { Layout } from 'components'
import withoutAuth from '../../hocs/without-auth'

const Login = () => (
  <>
    <Layout>
      <LoginView />
    </Layout>
  </>
)

export default withoutAuth(Login, '/affiliate-dashboard/analytics')

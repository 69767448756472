import { darkBlueGrey } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  color: ${darkBlueGrey};

  > h1 {
    font-size: 2.25rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
  }

  > p {
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: 0.25px;
    margin-bottom: 3rem;
    width: 90%;
    text-align: left;
  }

  ${IS_FRAME_MOBILE} {
    > h1 {
      width: 100%;
      margin-top: 0;
      font-size: 1.5rem;
    }

    > p {
      line-height: 1.57;
      margin-bottom: 1.5rem;
    }
  }
  ${IS_FRAME_TABLET} {
    > h1 {
      font-size: 1.375rem;
      margin-top: 0;
      width: 100%;
    }
    > p {
      line-height: 1.57;
      margin-bottom: 1.5rem;
      width: 80%;
    }
  }
`

export const Img = styled.img`
  width: 60%;

  ${IS_FRAME_MOBILE} {
    width: 70%;
  }
  ${IS_FRAME_TABLET} {
    width: 45%;
    margin: 1rem 0;
  }
`